<script>
 import { createEventDispatcher } from 'svelte';
 export let node;

 const dispatch = createEventDispatcher();

 $: question = node?.title
 $: isThird = node?.choices.length % 3 === 0
 $: isFourth = node?.choices.length % 4 === 0

 function imageUrl(image) {
     return server ? `//${server}/images/${ image }` : '/image/${ image }'
 } 

 function selectChoice(event) {
     const answer = event.srcElement.dataset.title
     const choice = node?.choices.find(choice => choice.title === answer)
     if (choice) {
         const title = choice?.title
         const goto = choice?.goto
         const description = choice?.description
		 const name = node?.name

         dispatch("goto", {
             "title": title,
             "goto": goto,
			 "name": name,
             "description": description
         })
     }
 }
</script>
<style>
 :global(ul) {
     list-style: none;
 }


 h3 {
     margin: 0 0 2rem;
     font-weight: bold;
     width: 100%;
     font-size: 2em;
 }

 .container {
     display: grid;
     grid-template-columns: 1fr;
     grid-column-gap: 2em;
     grid-row-gap: 2em;
 }

 @media screen and (min-width:640px) {
     .container {
         grid-template-columns: 1fr 1fr;
     }
 }

 .choice {
     width: 100%;
     border: 1px solid gray;
     border-radius: 5px;
     background-color: white;

     box-shadow: 0 0.05em 0.2em rgba(0, 0, 0, 0.2);
     transition-property: box-shadow;
     transition-timing-function: ease-out;
     transition-duration: 300ms;

     cursor: pointer;
     position: relative;
 }

 .choice:hover {
     box-shadow: 0 0.3em 0.75em rgba(0, 0, 0, 0.25);
 }


 .choice .copyright {
     position: absolute;
     top: 8.5em;
     left: 0.5em;
     color: white;
 }

 .choice .copyright i {
     display: none;
     position: absolute;
     top: 0;
     left: 0;
     width: 0;
     padding: 0.5em 1em;
     background-color: white;
     color: #333;
     text-decoration: none;
 }

 .choice .copyright:hover i {
     display: block;
 }

 .choice img {
     border-top-left-radius: 5px;
     border-top-right-radius: 5px;
     width: 100%;
     height: 10em;
     object-fit: cover;
     opacity: 0.7;
     transition-property: opacity;
     transition-timing-function: ease-out;
     transition-duration: 300ms;
     pointer-events: none;
 }

 .choice:hover img {
     opacity: 1;
 }

 .choice .title {
     display: block;
     font-weight: bold;
     padding-left: 1em;
     padding-right: 1em;
     margin-top: 0.5em;
     margin-bottom: 1em;
     pointer-events: none;
 }

 .choice .description {
     display: block;
     font-weight: 400;
     padding-left: 1em;
     padding-right: 1em;
     pointer-events: none;
 }
</style>

{#if node}
    {#if node.prime}
        {@html node.prime }
    {/if}
    <h3>{ node.title }</h3>
    <div class="container" class:isThird class:isFourth >
        {#each node.choices as choice}
            <div class="choice" data-title={ choice.title } on:click={ selectChoice }>
                <img src="{ imageUrl(choice.image) }" title={ choice.title } alt={ choice.title } />
                <strong class="title">{ choice.title }</strong>
                <p class="description">{ choice.description }</p>
                <span class="copyright">©<i>{ @html choice.copyright }</i></span>
            </div>
        {/each}
    </div>
{/if}
