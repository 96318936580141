<script>
 import { createEventDispatcher } from 'svelte'

 export let id = Math.random().toString(36).substr(2, 5)
 export let preselectActive = false
 export let disabled = false
 export let label = ""
 export let description = ""

 const dispatch = createEventDispatcher()
 function toggleSwitch(event) {
     dispatch('change', {
		 "id": id,
		 value: event.target.checked
     })
 }

</script>
<style>
 .switch {
     position: relative;
     display: grid;
     width: 100%;
     grid-template-rows: [title] auto [description] auto;
     grid-template-columns: [description] 1fr [switch] 2.5em;
     grid-template-areas: "title			switch"
     "description	switch";
     grid-column-gap: 1em;
 }

 input[type=checkbox] {
     pointer-events: all;
     position: absolute;
     visibility: hidden;
 }

 .title {
     cursor: pointer;
     font-weight: 400;
     line-height: 1.7em;
     grid-area: title;
 }

 .switchButton {
     cursor: pointer;
     position: relative;
     grid-area: switch;
     justify-content: center;

     width: 2.5em;
     height: 1.5em;

     background-color: white;
     border: 0.05em solid #333;

     border-radius: 1em;
     transition-property: background-color, border-color;
     transition-duration: 0.3s;
     transition-timing-function: ease-out;
 }

 .disabled .switchButton {
     pointer-events: none;
     opacity: 0.5;
 }

 :checked + .switch > .switchButton {
     background-color: #73b8b0;
 }

 .switchButton::before {
     content: " ";
     position: absolute;
     top: 0.2em;
     left: 0.15em;
     display: block;
     width: 1em;
     height: 1em;
     border-radius: 1em;
     background-color: white;
     border: 0.05em solid #333;

     transform: translateX(0);

     transition-property: background-color, border-color, transform;
     transition-duration: 0.3s;
     transition-timing-function: ease-out;
 }

 :checked + .switch > .switchButton::before {
     border-color: var(--color-neutral-black);
     transform: translateX(1em);
 }

 :checked + .switch.disabled > .switchButton::before {
     border-color: var(--color-neutral-medium);
 }

 .switch > .switchButton::after {
     content: "✔︎";
     position: absolute;
     top: -0.4em;
     opacity: 0;
     left: 0.8em;
     font-size: 0.6em;
     transition-delay: 0.3s;
     transition-property: opacity;
     transition-duration: 0.3s;
     transition-timing-function: ease-out;
     color: white;
 }

 :checked + .switch > .switchButton::after {
     content: "✔︎";
     opacity: 1;
 }

 .description {
     cursor: pointer;
     grid-area: description;
     font-size: 0.8em;
 }
</style>
<input { id }
    type="checkbox"
    checked={ preselectActive ? true : null }
    on:change={ toggleSwitch }
    { disabled} />
<div class="switch" class:disabled>
    <label for={ id } class="title">{ label }</label>
    <label for={ id } class="switchButton"></label>
    <label for={ id } class="description">{ description }</label>
</div>
