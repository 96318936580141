<style>
 .values {
     width: 100%;
     text-align: center;
 }

 .slider {
     width: 100%;
 }

</style>
<script>
 import { createEventDispatcher, onMount } from "svelte"
 import RangeSlider from "svelte-range-slider-pips";

 const dispatch = createEventDispatcher()

 export let node

 const min = 0
 export let values = [0]


 $: labels = node.datapoints

 $: label = labels[values[0]]

 $: answer = {
     "answer": label,
     humanAnswer: label,
     type: "slider",
     field: node
 }

 $: dispatch("change", answer)

 onMount(async () => {
     dispatch("change", answer)
 })

</script>
<div class="values"><strong>{ @html labels[values[0]]}</strong></div>
<div class="slider" style="--range-handle-focus: #5bd1d7; ">
     <RangeSlider bind:values
                  range="min"
             min={ min }
             pips={ true }
             max={ labels.length - 1}
             formatter={ v => labels[v]} />
</div>
