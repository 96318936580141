<script>
 import { createEventDispatcher, onMount } from 'svelte';
 import Switch from '../widgets/Switch.svelte'

 export let node;
 const dispatch = createEventDispatcher()

 $: question = node?.title
 $: hasWideColumns = node?.hasWideColumns

 export let choices = []
 $: answer = choices.join(", ")
 $: dispatch("change", {
     "answer": choices,
     type: 'multiple-choice',
     field: node,
     humanAnswer: answer
 })


 onMount(async () => {
     dispatch("change", {
         "answer": choices,
         humanAnswer: answer,
         type: "multiple-choice",
         field: node
     })
 })

 function selectChoice(id) {
     choices = choices.concat(id)
 }

 function unselectChoice(id) {
     choices = choices.filter(choice => choice !== id)
 }

 function handleChoice(event) {
     if (event.detail?.value) {
         selectChoice(event.detail?.id)
     }
     else {
         unselectChoice(event.detail?.id)
     }
 }

</script>
<style>
 .container {
     display: grid;
     grid-template-columns: 1fr;
     grid-column-gap: 6em;
     grid-row-gap: 1em;
     width: 100%;
 }

 @media screen and (min-width: 640px) {
     .container {
         grid-template-columns: 1fr 1fr;
     }

     .container.hasWideColumns {
         grid-template-columns: 1fr 1fr;
     }
 }

 @media screen and (min-width: 768px) {
     .container {
         grid-template-columns: 1fr 1fr 1fr;
     }

     .container.hasWideColumns {
         grid-template-columns: 1fr 1fr;
     }
}

 .choice {
     display: flex;
 }

</style>

{#if node}
    {#if node.title }<h2>{ node.title }</h2>{/if}
    <div class="container" class:hasWideColumns >
        {#each node.choices as choice}
            <div class="choice">
                <!-- -<img class="icon" src="icons/{ choice.icon }.svg" alt="Icon" />-->
                <Switch id="{ choice.title }"
                        label="{ choice.title }"
						preselectActive={ choices.includes(choice.title) }
                        on:change={ handleChoice  } />
            </div>
        {/each}
    </div>
{/if}
