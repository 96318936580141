<style> 
 .field {
     display: flex;
     flex-direction: column;
     
     align-items: flex-start;
     width: 100%;
 }

 @media screen and (min-width: 40rem) {
     .field {
         display: grid;
         grid-template-columns: 1fr 1fr;
         grid-template-columns: 1fr;
         grid-column-gap: 1em;
         align-items: center;

         margin-bottom: 1em;
     }
 }

 .field.isDate {
     align-items: center;
 }


 .field.isDropdown {
     grid-template-columns: 1fr;
     align-items: flex-start;
 }

 @media screen and (min-width: 40rem) {
     .field.isDropdown {
         align-items: center;
     }
 }

 
 @media screen and (min-width: 40rem) {
     .field.isDateRange {
         grid-template-columns: 1fr 2fr;
     }
 }

 .isCheckbox {
     display: grid;
     grid-template-columns: 2rem 1fr;
     grid-template-areas:
         "title title"
         "check label";
 }

 .isCheckbox strong {
     grid-area: title;
     margin-bottom: 0.5em;
 }


 select {
     height: 2em;
 }

 .daterange {
     display: block;
     padding: 0.5rem 1em;
     background-color: white;
     border: 0.05em solid #333;
     border-radius: 0.25em;
 }

 label {
     display: inline-block;
     margin-bottom: 0.5em;
 }

 .input-label {
     font-weight: 600;
 }

 input {
     width: 100%;
     display: inline-block;
 }

 input[type=text],
 input[type=email],
 input[type=tel],
 input[type=number],
 textarea {
     padding: 0.5em 1em;
     border-radius: 0.25em;
     border: 0.05em solid #333;
     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
     font-size: 1rem;
 }

 input[type=checkbox] {
     width: auto;
 }

 select {
     display: inline-block;
     width: 100%;
     border-radius: 0.25em;
     border: 0.05rem solid #333;
     font-size: 1em;
     color: #333;
     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
     background-color: white;
 }

 textarea {
     height: 5em;
     width: 100%;
 }

 .isOptional::after {
     content: "(optional)";
     display: inline-block;
     font-size: small;
     font-weight: 400;
     margin-left: 0.5em;
 }

</style>
<script>
 import { createEventDispatcher, onMount } from "svelte"
 import Datepicker from "svelte-calendar"
 import Slider from "./Slider.svelte"
 import Litepicker from 'litepicker';
 import MultipleChoice from "./MultipleChoice.svelte"
 import Range from "./Range.svelte"
 import Captcha from "./Captcha.svelte"

 export let node
 export let field
 export let state

 const dispatch = createEventDispatcher()

 const startDate = new Date(Date.now());
 const endDate = new Date(Date.now() + (3 * 365.25 * 24 * 60 * 60 * 1000))
 const weekDays = [
     ["Sonntag", "So"],
     ["Montag", "Mo"],
     ["Dienstag", "Di"],
     ["Mittwoch", "Mi"],
     ["Donnerstag", "Do"],
     ["Freitag", "Fr"],
     ["Samstag", "Sa"]
 ]

 const months = [
     ["Januar", "Jan"],
     ["Februar", "Feb"],
     ["März", "Mär"],
     ["April", "Apr"],
     ["Mai", "Mai"],
     ["Juni", "Jun"],
     ["Juli", "Jul"],
     ["August", "Aug"],
     ["September", "Sep"],
     ["Oktober", "Okt"],
     ["November", "Nov"],
     ["Dezember", "Dez"]
 ]

 const randomString = Math.random().toString(32).substring(2)

 let datapoints = undefined

 let rangePicker = undefined

 let rangeValues = [4, 6]
 let sliderValue = [0]
 let textValue = ""
 let numberValue = 1
 let boolValue = false
 let selected = undefined
 let choices = []

 $: isDate = field.type === 'date'
 $: isDropdown = field.type === 'dropdown'
 $: isDateRange = field.type === 'daterange'
 $: isCheckbox = field.type === 'checkbox'
 $: isOptional = !field.required

 $: fittingQuestion = state?.answers.find(a => a.question === node.title)
 $: fittingAnswer = fittingQuestion?.answer
 

 $: if (field.type === 'range' && fittingAnswer) {
	 const start = field.datapoints.findIndex(dp => dp === fittingAnswer?.answer.from)
	 const end = field.datapoints.findIndex(dp => dp === fittingAnswer?.answer.to)
	 rangeValues = [Math.max(start, 0), Math.max(end, 0)]
 }

 $: if (field.type === 'slider' && fittingAnswer) {
	 const startIndex = field.datapoints.findIndex(dp => dp === fittingAnswer?.answer)
	 sliderValue = [startIndex]
 }

 $: if (field.type === 'multiple-choice' && fittingAnswer) {
	 choices = fittingAnswer.answer
 }

 $: if (field.type === 'dropdown' && !fittingAnswer) {
	 selected = field.datapoints.at(0)
 }
 
 $: if (field.type === 'dropdown' && fittingAnswer) {
	 selected = fittingAnswer.answer
	 setTimeout(() => {
		 dispatchValue({
             question: field.label,
             answer: selected,
             humanAnswer: selected,
             type: 'dropdown',
             field: field
     })}, 200)
 }

 $: isStringField = field.type === 'text' || field.type === 'email' || field.type === 'tel' || field.type === 'textarea'

 $: if (isStringField && fittingAnswer) {
	 const isString = typeof fittingAnswer.answer === 'string'
	 const isObj = typeof fittingAnswer.answer === 'object'
	 if (isString) {
		 textValue = fittingAnswer.answer
	 }
	 if (isObj) {
		 textValue = fittingAnswer.answer[field.label] ?? ''
	 }
	 console.log("is text", textValue)
	 setTimeout(() => {
		 dispatchValue({
             question: field.label,
             answer: textValue,
             humanAnswer: textValue,
             type: 'text',
             field: field
     })}, 200)

	 //console.log(fittingAnswer)
	 //console.log("node", node)
	 //console.log("field", field)
 }

 $: if (field.type === 'number' && fittingAnswer) {
	 const isString = typeof fittingAnswer.answer === 'string'
	 const isObj = typeof fittingAnswer.answer === 'object'
	 if (isString) {
		 numberValue = fittingAnswer.answer
	 }
	 if (isObj) {
		 numberValue = fittingAnswer.answer[field.label]
	 }

	 setTimeout(() => {
		 dispatchValue({
             question: field.label,
             answer: numberValue.toString(10),
             humanAnswer: numberValue.toString(10),
             type: 'number',
             field: field
     })}, 200)

	 //console.log(fittingAnswer)
	 //console.log("node", node)
	 //console.log("field", field)
 }

 $: if (field.type === 'checkbox' && fittingAnswer) {
	 const isBool = typeof fittingAnswer.answer === 'boolean'
	 const isObj = typeof fittingAnswer.answer === 'object'
	 console.log(fittingAnswer)
	 if (isBool) {
		 boolValue = fittingAnswer.answer ?? null
	 }
	 if (isObj) {
		 boolValue = fittingAnswer.answer[field.label] ?? null
	 }

	 console.log("bool", boolValue)

	 setTimeout(() => {
		 dispatchValue({
             question: field.label,
             answer: boolValue ?? false,
             humanAnswer: boolValue ? "ja" : "nein",
             type: 'checkbox',
             field: field
     })}, 200)

	 //console.log(fittingAnswer)
	 //console.log("node", node)
	 //console.log("field", field)
 }


 $: if (field.type === 'daterange') {

	 const startDate = fittingAnswer ? new Date(fittingAnswer.from) : new Date()
	 const endDate = fittingAnswer ? new Date(fittingAnswer.to) : null
     requestAnimationFrame(() => {
         const elem = document.getElementById(randomString)
         const columns = window.screen.availWidth > 640 ? 2 : 1
         const options = {
             element: elem,
             buttonText: {
                 "apply": "Übernehmen",
                 "cancel": "Abbrechen",
             },
             format: "DD.MMM.YYYY",
             lang: "de-DE",
             numberOfMonths: columns,
             numberOfColumns: columns,
             singleMode: false,
             "startDate": startDate,
			 "endDate": endDate,
             tooltipText: {"one": "Tag", "other": "Tage"}
         }
         
         const dateRangePicker =  new Litepicker(options)

         const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
         dateRangePicker.on('selected', (a, z) => {
             dispatchValue(
                 {
                     from: a.dateInstance.toJSON(),
                     to: z.dateInstance.toJSON(),
                     humanAnswer: "von frühestens " + a.dateInstance.toLocaleDateString('de-DE', dateOptions) + " bis spätestens " + z.dateInstance.toLocaleDateString('de-DE', dateOptions),
                     type: "date-range",
                     field: field
             })
             dateRangePicker.hide()
         })


		 setTimeout(() => {
			 if (fittingAnswer) {
				 dispatchValue({
					 from: startDate.toJSON(),
					 to: endDate.toJSON(),
					 humanAnswer: "von frühestens " + startDate.toLocaleDateString('de-DE', dateOptions) + " bis spätestens " + endDate.toLocaleDateString('de-DE', dateOptions),
					 type: "date-range",
					 field: field
			 })}}, 200)
     })
 }

 onMount(async () => {
     if (field.type === 'number') {
		 dispatchValue({
			 question: field.label,
			 answer: numberValue.toString(10),
			 humanAnswer: numberValue.toString(10),
			 type: 'number',
			 field: field
		 })
	 }
     if (field.type === 'dropdown') {
		 console.log("selected", selected)
         dispatchValue({
			 question: field.label,
			 answer: selected,
			 humanAnswer: selected,
			 type: 'dropdown',
			 field: field
         })
     }
 })

 function dispatchValue(value) {
     dispatch("change", value)
 }

 function dispatchEventValue(event) {
     const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
     const humanAnswer = value === true ? 'ja' : (typeof value ==='boolean' ? 'nein' : event.target.value)
     dispatch("change", {
         question: field.label,
         answer: value,
         humanAnswer: humanAnswer,
         type: event.target.type,
         field: field
     })
 }

</script>

<div class="field" class:isDate class:isDropdown class:isDateRange class:isCheckbox>
    {#if field.type === "text"}
        {#if field.label }<label id="{ field.label }" for="{ field.name }" class="input-label"  class:isOptional>{ field.label }</label>{/if}
        <input type="text" name="{ field.name }"  placeholder="{ field.placeholder }"  on:keyup={ dispatchEventValue } value={ textValue } />
		
    {:else if field.type === "number"}
        {#if field.label }<label id="{ field.label }" for="{ field.name }" class="input-label">{ field.label }</label>{/if}
        <input type="number" name="{ field.name }" placeholder="{ field.placeholder }" min="1" value={ numberValue } steps="1" on:change={ dispatchEventValue }  />
		
    {:else if field.type === "email"}
        {#if field.label }<label for="{ field.name }" class="input-label">{ field.label }</label>{/if}
        <input type="email" id="{ field.label }" name="{ field.name }" placeholder="{ field.placeholder }" on:keyup={ dispatchEventValue } value={ textValue } />
		
    {:else if field.type === "tel"}
        {#if field.label }<label for="{ field.name }" class="input-label">{ field.label }</label>{/if}
        <input type="tel" id="{ field.label }" name="{ field.name }" placeholder="{ field.placeholder }" on:keyup={ dispatchEventValue } value={ textValue } />
		
    {:else if field.type === "checkbox"}
        {#if field.title }<strong>{ field.title }</strong>{/if}
        <input id={ field.name } type="checkbox" name="{ field.name }" class="input-label" placeholder="{ field.placeholder }" on:change={ dispatchEventValue } checked={ boolValue }/>
        {#if field.label }<label for="{ field.name }">{ field.label }</label>{/if}
		
    {:else if field.type === "textarea"}
        {#if field.label }<label for="{ field.name }" class="input-label">{ field.label }</label>{/if}
        <textarea name="{ field.name }" placeholder="{ field.placeholder }" on:keyup={ dispatchEventValue }>{ textValue }</textarea>
		
    {:else if field.type === "daterange"}
        {#if field.label }<label for="{ field.name }">{ field.label }</label>{/if}
        <input type="text" id="{ randomString }" class="daterange" placeholder="Bitte wählen Sie einen Zeitrahmen">
		
    {:else if field.type === "range"}
        <Range node="{ field }" on:change values={ rangeValues }/>
		
    {:else if field.type === "slider"}
        <Slider node="{ field }" on:change values={ sliderValue } />
		
    {:else if field.type === "dropdown"}
        <select on:change={ dispatchEventValue }>
            {#each field.datapoints as datapoint}
                <option selected={ selected === datapoint ? true : null }>{ datapoint }</option>
            {/each}
        </select>
		
    {:else if field.type === "multiple-choice"}
        <MultipleChoice node="{ field }" on:change choices={ choices } />
		
    {:else if field.type === "captcha"}
        <Captcha node="{ field }" on:change />
    {/if}
</div>
