<script>
 import SingleChoice from './nodes/SingleChoice.svelte'
 import Form from "./nodes/Form.svelte"
 import Sendmail from "./nodes/Sendmail.svelte"
 import { onMount } from 'svelte';
 import { fly } from 'svelte/transition';

 const url = server ? `//${server}/configurator.json` : '/configurator.json'

 onMount(() => {
     fetch(url)
         .then(response => response.json())
         .then(data => {
             nodes = data.nodes
             currentName = "start"
             persistStack([])
         });
 });

 const stackName = "configurator-stack"
 
 let nodes = []
 let currentName = undefined

 let prefilledIndex = Number.POSITIVE_INFINITY
 
 $: currentNode = currentName ? Object.assign(nodes[currentName], {name: currentName}) : undefined

 $: currentState = currentName ? liquidifyStack().find(s => s.name === currentName) : undefined


 function liquidifyStack() {
     return JSON.parse(window.localStorage.getItem(stackName))
 }

 function persistStack(stack) {
     window.localStorage.setItem(stackName, JSON.stringify(stack))
 }

 function pushState(stack, state, goto = true) {
     if (goto) {
		 currentName = state.goto
	 }
     return stack.concat(state)
 }

 function cleanupStack(oldStack, prefilledIndex, nextName) {
	 return oldStack.filter((entry, index, stack) => {
		 if (index < prefilledIndex) return true
		 if (index === prefilledIndex) return entry.name === nextName
		 return stack.at(prefilledIndex).name === nextName
	 })
 }
	 

 function goToNext(event) {
	 const currentNodeContent = getContent(event, currentNode)
	 const fullStack = liquidifyStack()
	 const stack = prefilledIndex < fullStack.length ? cleanupStack(fullStack, prefilledIndex, currentNodeContent.goto) : fullStack

	 prefilledIndex = prefilledIndex < fullStack.length ? prefilledIndex + 1 : Number.POSITIVE_INFINITY

	 const hasFittingEntry = stack.some(s => s.name === currentNodeContent.name)
	 
	 if (hasFittingEntry) {
		 // replace old entry with new one
		 persistStack(stack.map(entry => {
			 return entry.name === currentNodeContent.name ? currentNodeContent : entry
		 }))
		 currentName = currentNodeContent.goto
	 }
	 else {
		 persistStack(pushState(liquidifyStack(), currentNodeContent))
	 }

     window.requestAnimationFrame(() => {
         const top = document.getElementById("badetier-configurator").getBoundingClientRect().top
         window.scrollBy(0, top)
     })
 }

 function getContent(event, currentNode) {
	 let content = undefined;
     switch (currentNode.type) {
         case "single-choice":
             content = {
                 name: currentNode?.title,
                 question: currentNode.title,
                 answer: event.detail.title,
                 humanAnswer: event.detail.title
             }
             break;
         case 'form':
             content = {
                 answers: currentNode.fields.map(field => {
                     return {
                         question: field.title,
                         answer: event.detail.answers[field.title]
                     }
                 }),
             }
             break
         default:
             break;
     }

     content.name = event.detail.name
	 content.goto = event.detail.goto
     content.type = currentNode.type
	 return content
 }

 function update(event) {
	 const currentNodeContent = getContent(event, currentNode)
	 const stack = liquidifyStack()
	 const entry = stack.find(s => s.name === currentNodeContent.name)
	 if (entry) {
		 // replace old answers by upate
		 entry.answers = currentNodeContent.answers
		 persistStack(stack)
	 }
	 else {
		 persistStack(pushState(stack, currentNodeContent, false))
	 }
 }

 function goToPrevious(event) {
	 const items = liquidifyStack()
	 const itemIndex = items.findLastIndex(x => x.name === currentName)
	 prefilledIndex = itemIndex === -1 ? Number.POSITIVE_INFINITY : itemIndex
	 const prevIndex = itemIndex === -1 ? items.length - 1 : prefilledIndex - 1
     currentName = prevIndex < 0 ? "start" : items.at(prevIndex).name
 }


</script>
<style>
 :global(*) {
     box-sizing: border-box;
 }


 :global(.btn-form) {
     display: inline-block;
     margin-top: 2em;
     padding: 1rem 2em;
     background-color: hsl(183deg 61% 43%);
     color: white;
     text-decoration: none;
     border-radius: 0.25em;
     align-self: end;
     margin-left: auto;
     transition-duration: 300ms;
     transition-property: transform, box-shadow;
     transition-timing-function: ease-out;
     transform: translateY(0);
     box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
 }

 :global(.btn-form:hover) {
     transform: translateY(-0.1em);
     box-shadow: 0 0.3em 0.4 rgba(0, 0, 0, 0.2);
 }

 .configurator {
     width: 100%;
     height: 100%;
     background-color: white;
     padding: 2em;
     font-size: 16px;
 }

 .previous-button {
     margin-bottom: 2em;
     display: inline-block;
     color: #333;
     text-decoration: none;
     font-weight: bold;
     border: 1px solid #333;
     padding: 0.5em 1em;
     border-radius: 0.25em;
 }

 .form {
     display: flex;
     flex-direction: column;
     flex-wrap: wrap;
 }
</style>
<div class="configurator">
    {#if currentNode}
        {#if currentName !== "start"}
            <a on:click={ goToPrevious } href="#previous" class="previous-button">← Zurück</a>
        {/if}
        {#if currentNode.type === "single-choice"}
            <div
                class="single-choice"
                in:fly="{{ delay: 200, duration: 200, x: 100, opacity: 0}}"
                out:fly="{{ duration: 200, x: 100, opacity: 0}}">
                <SingleChoice node={ currentNode } on:goto={ goToNext } />
            </div>
        {:else if currentNode.type === "form"}
            <div class="form"
                 in:fly="{{ delay: 200, duration: 200, x: 100,  opacity: 0}}"
                 out:fly="{{ duration: 200, x: 100, opacity: 0}}">
                <Form
					node={ currentNode }
				    state={ currentState }
				    on:updated={ update }
					on:goto={ goToNext } />
            </div>
        {:else if currentNode.type === "sendmail"}
            <div class="sendmai"
                 in:fly="{{ delay: 200, duration: 200, x: 100, opacity: 0}}"
                 out:fly="{{ duration: 200, x: 100, opacity: 0}}">
                <Sendmail node={ currentNode } state={ liquidifyStack() } />
            </div>
        {/if}
    {/if}
</div>
