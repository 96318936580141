<script>
 import { createEventDispatcher } from "svelte"
 export let node

 const dispatch = createEventDispatcher();

 const riddles = [
     { question: "0 + 1", answer: "1" },
     { question: "1 + 1", answer: "2" },
     {
	 question: "3 + 0",
	 answer: "3"	
     },
     {
	 question: "3 + 1",
	 answer: "4"	
     },
     {
	 question: "2 + 3",
	 answer: "5"
     },
     {
	 question: "4 + 2",
	 answer: "6"	
     },
     {
	 question: "6 + 1",
	 answer: "7"	
     },
     {
	 question: "3 + 5",
	 answer: "8"	
     },
     {
	 question: "7 + 2",
	 answer: "9"	
     }
 ]

 let answer = NaN
 let isChanged = false

 $: randomRiddle = Math.floor(Math.random() * 9)
 $: q = riddles[randomRiddle].question
 $: a = riddles[randomRiddle].answer

 $: isCorrect = a === answer
 $: console.log(a, answer)

 function dispatchEventValue(event) {
	 const value = event.target.value
	 isChanged = true
	 answer = value
	 if (a === value) {
		 dispatch("change", {
			 question: 'security-question',
			 answer: event.target.value,
			 humanAnswer: event.target.value,
			 x: a,
			 type: 'security',
			 field: node
		 })
	 }
	 else {
		  dispatch("change", {
			 question: 'security-question',
			 answer: '',
			 humanAnswer: '',
			 x: a,
			 type: 'security',
			 field: node
		 })
	 }
 }

</script>
<style>
 label {
     display: inline-block;
     margin-bottom: 0.5rem;
 }

 .input-label {
     font-weight: 600;
 }

 input {
     width: 100%;
     display: inline-block;
 }

 input[type=text] {
     padding: 0.5em 1em;
     border-radius: 0.25em;
     border: 0.05em solid #333;
     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
     font-size: 1em;
 }
</style>

{#if node.label }
    <label for="{ node.label }" class="input-label">{ node.label }</label>
{/if}
<span class="security-question">Was ergibt { q }?</span>
<input type="text" name="{ node.name }" placeholder="{ node.placeholder }" on:keyup={ dispatchEventValue } />
{#if isChanged && !isCorrect }
	<div style="color: #ff3333; margin-top: 0.25rem;">Dieses Ergebnis ist falsch</div>
{/if}
<input type="hidden" name="x" value="{ a }" />
