<style>

 :global(.litepicker) {
     font-size: 1.25em;
}

 .field {
     width: 100%;
 }

 .image {
     display: block;
     width: 100%;
     height: 10em;
     object-fit: cover;
 }

 .image-block {
     position: relative;
     width: 100%;
 }

 .image-block img {
     opacity: 1;
 }

 .image-block .copyright {
     position: absolute;
     top: 8.5em;
     left: 0.5em;
     color: white;
 }

 .image-block .copyright i {
     display: none;
     position: absolute;
     top: -1em;
     left: 0;
     width: 20em;
     padding: 0.5em 1em;
     background-color: rgba(255, 255, 255, 0.8);
     color: #333;
     text-decoration: none;
 }

 .image-block .copyright:hover i {
     display: block;
 }

 .image-block img {
     border-top-left-radius: 5px;
     border-top-right-radius: 5px;
     width: 100%;
     height: 10em;
     object-fit: cover;
     transition-property: opacity;
     transition-timing-function: ease-out;
     transition-duration: 300ms;
     pointer-events: none;
 }

 .btn-form {
     padding: 1em 2em;
 }

 .btn-form[disabled] {
     opacity: 0.5;
     pointer-events: none;
 }

 aside {
     font-size: small;
     margin-top: 2em;
 }

 @media screen and (min-width: 768px) {
     aside {
         text-align: right;
     }
 }

 aside a {
     display: block;
     margin-bottom: 0.5em;
     color: hsl(183deg 61% 43%);
 }

</style>
<script>
 import { createEventDispatcher } from 'svelte';
 import Datepicker from "svelte-calendar";
 import Group from "../widgets/Group.svelte"
 import Controls from "../widgets/Controls.svelte"

 export let node;
 export let state;

 const dispatch = createEventDispatcher();

 const startDate = new Date(Date.now());
 const endDate = new Date(Date.now() + (3 * 365.25 * 24 * 60 * 60 * 1000))
 const weekDays = [
     ["Sonntag", "So"],
     ["Montag", "Mo"],
     ["Dienstag", "Di"],
     ["Mittwoch", "Mi"],
     ["Donnerstag", "Do"],
     ["Freitag", "Fr"],
     ["Samstag", "Sa"]
 ]

 const months = [
     ["Januar", "Jan"],
     ["Februar", "Feb"],
     ["März", "Mär"],
     ["April", "Apr"],
     ["Mai", "Mai"],
     ["Juni", "Jun"],
     ["Juli", "Jul"],
     ["August", "Aug"],
     ["September", "Sep"],
     ["November", "Nov"],
     ["Dezember", "Dez"]
 ]

 const answers = {}

 $: image = node?.image
 $: img  = server ? `//${server}/images/${ image }` : '/image/${ image }'

 $: required = node.fields
                   .reduce((acc, current) => {
                       return current.type === 'group' ? acc.concat(current.fields) : acc.concat(current)
                   }, [])
                   .filter(current => current.required)


 let filledRequired = []

 $: disabled = filledRequired.length < required.length ? true : null

 function gotoNext(event) {
     const value = {
         title: node.title,
         answers: event.detail.answers
     }

     filledRequired = []

     dispatch("goto", {
         goto: node.goto,
		 name: node.name,
         title: node.title,
         answers: answers 
     })
 }

 function handleChange(event) {
     if (event.detail.answer.field?.required) {
         const answer =
             event.detail.answer.type === 'date-range' ||
             event.detail.answer.type === 'multiple-choice' ?
             event.detail.answer.humanAnswer : event.detail.answer.answer

         if (answer &&
             filledRequired.every(f => JSON.stringify(f) !== JSON.stringify(event.detail.answer.field))) {
             filledRequired = filledRequired.concat(event.detail.answer.field)
         }
		 
         if (!answer) {
             filledRequired = filledRequired.filter(f => JSON.stringify(f) !== JSON.stringify(event.detail.answer.field))
         }
     }

     answers[event.detail.question] = event.detail.answer
	 dispatch("updated", {
		 answers: answers,
		 goto: node.goto,
		 name: node.name,
		 title: node.title
	 })
 }

 $: unfilledRequired = node.fields
                           .filter(f => f.type === 'group')
                           .filter(g => g.fields.some(r => r.required))
                           .map(g => g.title)
                           .filter(name => {
                               return Object.keys(answers).every(a => a !== name) ||
                                      answers[name].humanAnswer === ''
                           })
 /*
    .map(x => {
    console.log(x, answers[x])
    console.log("all answers", answers)
    return x
    })
    .filter(f => Object.keys(answers).every(a => a !== f))*/


</script>

{#if node}
    {#if image}
        <div class="image-block">
            <img src="{ img }" alt="Title" class="image" />
            <span class="copyright">©<i>{ @html node.copyright }</i></span>
        </div>
    {/if}
    {#if node.title }<h2>{ node.title }</h2>{/if}
    {#each node.fields as field}
        <div class="field">
            {#if field.type === "group"}
                <Group node={ field } on:change={ handleChange } { state } />
            {:else}
                <Controls field={ field } on:change={ handleChange } { state } />
            {/if}
        </div>
    {/each}

    {#if unfilledRequired.length > 0}
        <aside>
            <h4>Bitte füllen Sie noch diese Felder aus</h4>
            {#each unfilledRequired as unfilled}
                <a href="#{encodeURIComponent(unfilled)}">{ unfilled }</a>
            {/each}
        </aside>
    {/if}

    <a href="#next" on:click="{ gotoNext }" class="btn-form" { disabled }>{ node.gotoButtonLabel }</a>
{/if}
