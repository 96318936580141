<style>
 .values {
     width: 100%;
     text-align: center;
 }

 .range {
     width: 100%;
 }

</style>
<script>
 import { createEventDispatcher, onMount } from "svelte"
 import RangeSlider from "svelte-range-slider-pips";

 export let node
 export let values = [4,6]
 
 const dispatch = createEventDispatcher()
 

 const min = 0


 $: labels = node.datapoints
 $: firstLabel = labels[values[0]]
 $: secondLabel = labels[values[1]]

 $: response = "von " + firstLabel + " bis zu " + secondLabel

 $: answer = {
     "answer": {
         from: firstLabel,
         to: secondLabel
     },
     humanAnswer: response,
     field: node,
     type: "range"
 }

 $: dispatch("change", answer)

 onMount(async () => {
     dispatch("change", answer)
 })

</script>
<div class="values">mindestens <strong> { firstLabel }</strong> <span>bis höchstens</span> <strong>{ secondLabel }</strong></div>
<div class="range" style="--range-handle-focus: #5bd1d7; --range-handle: #58b8bb;">
    <RangeSlider bind:values
                 range
                 min={ min }
                     max={ labels.length - 1}
                 formatter={ v => labels[v]} />
</div>
