import App from './App.svelte';

const elem = document.getElementById("badetier-configurator")

//var div = document.createElement('DIV');
//var script = document.currentScript;
//script.parentNode.insertBefore(div, script);

const configurator = new App({
  target: elem,
  props: {},
});
