<svelte:head>
    <!-- Global site tag (gtag.js) - Google Ads: 350223680 -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=AW-350223680">
    </script>
    <script>
     window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-350223680');
    </script>
</svelte:head>
<style>
 h1 {
     font-size: 2.5em;
 }

 h2 {
     font-size: 1.5em;
 }

 .info {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     
 }

 .image {
     display: inline-block;
     width: 120px;
     height: 120px;
     animation:spin 4s linear infinite;
 }

 @keyframes spin { 
                 100% { 
                     transform:rotate(360deg); 
                 } 
                 }
</style>
<script>
 import { createEventDispatcher, onMount } from 'svelte';
 import Datepicker from "svelte-calendar";
 import Group from "../widgets/Group.svelte"
 import Controls from "../widgets/Controls.svelte"

 export let node;
 export let state;
 let isLoading = true

 const dispatch = createEventDispatcher();

 $: image = node?.image

 $: sendableData = state
     .map(s => {
         switch(s.type) {
             case 'single-choice':
                 return { question: s.question, answer: s.humanAnswer }
             case 'form':
                 return s.answers.map(a => {
                     switch (a.answer?.type) {
                         case 'text': {
                             const aaq = a.answer.question ?? ''
                             const r =  {
                                 question: `${a.question} ${aaq}`,
                                 answer: a.answer.humanAnswer
                             }
                             return r
                         }
                         case 'security': {
                             const aaq = a.answer.question ?? ''
                             const q = `${a.question} ${aaq}`
                             const an = a.answer.humanAnswer
                             const r =  {
                                 question: q,
                                 answer: an,
                                 check: a.answer.x,
                                 type: 'security'
                             }
                             return r
                         }
                         default: {
                             const aaq = a.answer?.question ?? ''
                             const q = `${a.question} ${aaq}`
                             const an = a.answer?.humanAnswer
                             const r =  {
                                 question: q,
                                 answer: an
                             }
                             return r
                         }
                     }
                 })
             default:
                 return null
         }
     })
     .reduce((acc, current) => {
         return acc.concat(current)
     }, [])

 $: sendable = {
     answer: sendableData.find(n => n.type === 'security').answer,
     check: sendableData.find(n => n.type === 'security').check,
     data: sendableData
 }

 $: url = server ? 'https://' + server : ''

 onMount(async () => {
     const response = await fetch(url + '/services/notify.php', {
         method: 'POST', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
             'Content-Type': 'application/json'
             // 'Content-Type': 'application/x-www-form-urlencoded',
         },
         redirect: 'follow', // manual, *follow, error
         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         body: JSON.stringify(sendable) // body data type must match "Content-Type" header
     });
     const content  = response.text();

     setTimeout(() => {
         isLoading = false;
         gtag('event', 'conversion', {'send_to': 'AW-350223680/8NO6CKDsiIQDEMD6_6YB'});
     }, 3000)
 })

 function gotoNext(event) {
     dispatch("goto", {
         "goto": node.goto
     })
 }

</script>

{#if node}
    {#if isLoading }
        <div class="info">
            <svg xmlns="http://www.w3.org/2000/svg" class="image" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <h3>einen Moment bitte&hellip;</h3>
            <div>Daten werden an den Server gesendet</div>
        </div>
    {:else }
        {#if node.title}
            <h1>{ node.title }</h1>
        {/if}
        {#if node.subtitle}
            <h2>{ node.subtitle }</h2>
        {/if}
    {/if}
{/if}
