<style>

 h3 {
     margin-top: 3em;
     margin-bottom: 1em;
 }

 .content {
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     
 }

 @media screen and (min-width: 40rem) {
     .content {
         display: grid;
         grid-template-columns: 1fr 1fr;
         grid-column-gap: 2em;
     }
 }

 .content.isSingleColumn {
     grid-template-columns: 1fr;
 }

 .isOptional::after {
     content: "(optional)";
     display: inline-block;
     font-size: small;
     font-weight: 400;
     margin-left: 0.5em;
 }
 
</style>
<script>
 import { createEventDispatcher } from "svelte"
 import Controls from "./Controls.svelte"
 
 export let node;
 export let state;

 $: title = node?.title
 $: isSingleColumn = node.fields.length === 1

 $: isOptional = node.fields.every(f => !f.required)
 $: showOptionalInControl = node.fields.length > 1

 const dispatch = createEventDispatcher()

 const answers = {}
 

 function handleChange(event) {

	 if (event.detail.question && node.fields.length > 1) {
 		 answers[event.detail.question] = event.detail.humanAnswer
		 const hAnswer = Object.keys(answers)
							   .filter(key => answers[key] !== '')
							   .map(key => [key, answers[key]])
							   .map(entry => entry.join(": "))
							   .join(", ")
		 

		 const ans = Object.assign({}, event.detail, { humanAnswer: hAnswer, question: "", answer: answers })
		 dispatch("change", {
			 question: title,
			 answer: ans
		 })
	 }
	 else {
		 dispatch("change", {
			 question: title,
			 answer: event.detail
		 })
	 }
 }

</script>
{#if node}
    <div class="group" id={ title }>
        <h3 class:isOptional>{ title }</h3>
        <div class="content" class:isSingleColumn>
            {#each node.fields as field}
                <Controls on:change={ handleChange } { state } { node } { field }/>
            {/each}
        </div>
    </div>
{/if}
